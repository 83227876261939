import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const About = ({ location }) => (
  <StaticQuery
    query={aboutQuery}
    render={data => (
      <Layout location={location} title="O mnie">
        <SEO
          title="O mnie"
          keywords={[`about`, `biografia`, `autor`, "programista"]}
        />
        <article>
          <h1>Hej, ja nazywam się Bartosz Wiśniewski</h1>
          <p>
            Jestem programistą, bez konkretnej technologii, choć zwykle podobają mi się pomysły Google. Swoją przygodę z programowaniem zacząłem już w
            wieku 14 lat i jako samouk stale rozwijam się w tym kierunku. Często
            można mnie spotkać na różnych konferencjach czy meetupach we
            Wrocławiu.
          </p>
          <h3>Mam ciekawe doświadczenie w IT</h3>
          <ul>
            <li>W 5 klasie podstawówki założyłem świetny serwer Minecrafta</li>
            <li>Rozwiązywałem problemy na naszym polskim SPOJu</li>
            <li>
              Bawiłem się w Unity i libGDX - stworzyłem i wydałem kilka
              aplikacji na Androida
            </li>
            <li>
              Brałem udział w konkursach Google Code-in 2014 i 2015 gdzie
              współpracowałem z azjatycką organizacją FOSSASIA
            </li>
            <li>Zrobiłem miesiąc z Stack Overflow osiągając 2000 punktów</li>
            <li>Prawdopodobnie jedyny nieletni uczestnik kilku konferencji</li>
            <li>
              Odbyłem kilka rozmów kwalifikujących, w 4 różnych technologiach
            </li>
          </ul>
          <h3>Technologia nie ma znaczenia</h3>
          <p>
            Odkąd zacząłem się uczyć to zmieniałem technologie jak skarpetki. To
            pozwoliło mi niesamowicie poszerzyć sobie horyzonty. Widzę jak różne
            technologie spotykają się z problemami, które w innych zostały dawno
            rozwiązane.
          </p>
          <div className="custom-block image">
            <Image fluid={data.techImage.childImageSharp.fluid} alt={"Technologiczna chmurka"} />
            <p>To oczywiście nie wszystko</p>
          </div>
          <p />
          <h3>Poza IT</h3>
          <p>
            Eksperymentuje w kuchni z niecodziennymi pomysłami — sous vide,
            aromatyzowanie czy fermentacja. Mówiąc o tym ostatnim — jestem domowym piwowarem, ale mam przy tym
            dość mało popularny warsztat.
          </p>
          <p>
            Zdarzało mi się być grafikiem, operatorem kamery, fotografem i ostatecznie montażystą. Od pewnego czasu nagrywam spotkania wrocławskiego JUGa.
          </p>
          <p>
            Jestem też majsterkowiczem, więc zdarza mi się badać, naprawiać przeróżne rzeczy mimo że się to często nie opłaca.
          </p>
          <p>Piję dużo przeróżnej herbaty.</p>
          <p>No i przytulam koty.</p>
        </article>
      </Layout>
    )}
  />
)

const aboutQuery = graphql`
  query {
    techImage: file(absolutePath: { regex: "/tech.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default About
